import React,{useState,useEffect} from 'react';
import ContactForm from '../../components/form/contactForm';
// import firebase from 'gatsby-plugin-firebase';
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import house1 from "../../../static/images/material-mail.png"


const Contact = () => {


    // const socialGoogleSignin = () => {
    //     var provider = new firebase.auth.GoogleAuthProvider();
    //     firebase.auth().signInWithPopup(provider).then(function (result) {
    //         var token = result.credential.accessToken;
    //         var user = result.user;
    //         var uid = result.user.uid;
    //         var displayName = result.user.displayName;
    //         var email = result.user.email;

    //     }).catch((error) => {
    //         console.log(error)
            
    //       });
    // }
    // const socialFacebookSignin = () => {
    //     var provider = new firebase.auth.FacebookAuthProvider();

    //     firebase
    //         .auth()
    //         .signInWithPopup(provider)
    //         .then((result) => {

    //             /** @type {firebase.auth.OAuthCredential} */
    //             var credential = result.credential;

    //             var user = result.user;
    //             var accessToken = credential.accessToken;

    //         })
    //         .catch((error) => {
    //             var errorCode = error.code;
    //             var errorMessage = error.message;
    //             var email = error.email;
    //             var credential = error.credential;

    //         });

    // }

    // const socialTwitterSignin = () => {
    //     var provider = new firebase.auth.TwitterAuthProvider();

    //     firebase
    //         .auth()
    //         .signInWithPopup(provider)
    //         .then((result) => {

    //             /** @type {firebase.auth.OAuthCredential} */
    //             var credential = result.credential;

    //             var token = credential.accessToken;
    //             var secret = credential.secret;

    //             var user = result.user;
    //             // ...
    //         }).catch((error) => {

    //             // Handle Errors here.
    //             var errorCode = error.code;
    //             var errorMessage = error.message;
    //             // The email of the user's account used.
    //             var email = error.email;
    //             // The firebase.auth.AuthCredential type that was used.
    //             var credential = error.credential;
    //             // ...
    //         });


    // }

    // const socialAppleSignin = () => {
    //     var provider = new firebase.auth.OAuthProvider('apple.com');

    //     firebase
    //         .auth()
    //         .signInWithPopup(provider)
    //         .then((result) => {



    //             /** @type {firebase.auth.OAuthCredential} */
    //             var credential = result.credential;

    //             // The signed-in user info.
    //             var user = result.user;

    //             // You can also get the Apple OAuth Access and ID Tokens.
    //             var accessToken = credential.accessToken;
    //             var idToken = credential.idToken;

    //             // ...
    //         })
    //         .catch((error) => {

    //             // Handle Errors here.
    //             var errorCode = error.code;
    //             var errorMessage = error.message;
    //             // The email of the user's account used.
    //             var email = error.email;
    //             // The firebase.auth.AuthCredential type that was used.
    //             var credential = error.credential;

    //             // ...
    //         });
    // }
    const [storage,setStorage] =useState(false);

    useEffect(() => {
        const storedData =localStorage.getItem("userInfo") ;
         if (!storedData) {
          
             setStorage(false);
         }else{
            setStorage(true);
           
         }
    },[]);
    return (

        <div>
            <Navbar  isLogin={storage} />

            <div className='container-fluid p-100 contactForm'>
                <div className='row m-0'>
                    <div className='col-lg-12 col-xl-12 col-md-12 p-0'>
                        <div>
                            <h2>Contact Us</h2>
                            <h4>We'd love to hear from you. You can shoot us an email at</h4>
                            <h3>
                                <span><img  src={house1} alt="house1"  /></span>
                                <a href="#">info@keywe.com</a> 
                            </h3>
                            <div className="hr-section"><span className="hr-text">or</span></div>
                            <h5>you can contact us by filling out the form below</h5>
                        </div>
                        <ContactForm />
                    </div>
                </div>
            </div>
            <Footer isLogin={storage} />
        </div>
    )
}

export default Contact;